import Vue from 'vue'
import Vuex from 'vuex'

import notifications from './modules/notifications'
import prompt from './modules/prompt'
import user from './modules/user'
import roles from './modules/roles'
import permissions from './modules/permissions'
import userAccounts from './modules/userAccounts'
import applicants from './modules/applicants'
import registrants from './modules/registrants'
import interviewees from './modules/interviewees'
import finalInterviewees from './modules/finalInterviewees'
import approved from './modules/approved'
import processing from './modules/processing'
import legalProcesses from './modules/legalProcesses'
import archivedApplicants from './modules/archivedApplicants'
import deniedApplicants from './modules/deniedApplicants'
import domestic from './modules/domestic'
import payments from './modules/payments'
import jobs from './modules/jobs'
import opportunities from './modules/opportunities'
import employers from './modules/employers'
import loanRequests from './modules/loanRequests'
import loanEligibles from './modules/loanEligibles'
import loanAgreements from './modules/loanAgreements'
import interviewers from './modules/interviewers'
import interviews from './modules/interviews'
import countries from './modules/countries'
import applicantNotes from './modules/applicantNotes'
import jobOffers from './modules/jobOffers'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
    modules: {
        notifications,
        prompt,
        user,

        applicantNotes,
        roles,
        permissions,
        userAccounts,
        applicants,
        registrants,
        interviewees,
        finalInterviewees,
        approved,
        processing,
        domestic,
        legalProcesses,
        archivedApplicants,
        deniedApplicants,
        opportunities,
        jobs,
        employers,
        loanRequests,
        loanEligibles,
        loanAgreements,
        interviewers,
        interviews,
        payments,
        jobOffers,
        countries,
    },
    strict: debug,
})
