<template>
  <v-row>
    <v-col
      cols="12"
      >
      <MeetingsCalendar/>

    </v-col>

    <AppFormDialog
        subject="Meeting"
        :value="showDialog"
        width="800"
    >

        <template #form>
            <CalendarEventForm/>
        </template>

        <template #activator="{ on, attrs }">
            <v-fab-transition>
                <v-btn
                    color="green"
                    v-bind="attrs"
                    v-on="on"
                    @click="onCreate"
                    bottom
                    dark
                    fab
                    fixed
                    large
                    right
                    >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </v-fab-transition>
          </template>
    </AppFormDialog>
  </v-row>
</template>

<script>
import AppFormDialog from './AppFormDialog'
import MeetingsCalendar from './MeetingsCalendar'
import CalendarEventForm from './meeting/CalendarEventForm'

export default {
  name: 'Meetings',
  components: {
    AppFormDialog,
    MeetingsCalendar,
    CalendarEventForm
  },

  data() {
      return {
          showDialog: false
      }
  },
 
    methods: {
        onCreate() {
            this.showDialog = true
        }
    }
}
</script>
